import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Flex } from "../../../../components/Box";
import { UserMenuProps } from "./types";
import { UserMenuItem } from "./styles";

export const StyledUserMenu = styled(Flex)`
  width: fit-content;
  height: 35px;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.gradientPrimary};
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.4s all;
  position: relative;
  padding: 0px 10px;
  z-index: 990;
`;

export const LabelText = styled.div`
  color: #000;
  display: none;
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background: #fff;
  border-radius: 16px;
  pointer-events: auto;
  width: 250px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.08);
  position: absolute;
  opacity: 1;
  z-index: 989;
  top: 35px;
  right: 0px;
  visibility: visible;
  transition: 0.4s all;
  &.showMenu {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s all;
  }
  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const BlockInfoWallet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InfoAccountConnect = styled.div`
  color: #000;
  font-family: SUISSE_BOLD;
`;

const UserMenu: React.FC<UserMenuProps> = ({ account, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);

  const accountEllipsis = account ? `${account.substring(0, 5)}••••${account.substring(account.length - 4)}` : null;

  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Flex alignItems="center" height="100%" {...props} style={{ position: "relative" }} onClick={handleOpenMenu}>
      <StyledUserMenu>
        <BlockInfoWallet>
          <InfoAccountConnect>{accountEllipsis}</InfoAccountConnect>
        </BlockInfoWallet>
      </StyledUserMenu>
      <Menu className={`${!isOpen ? "showMenu" : ""}`} isOpen={isOpen} ref={userMenuRef}>
        <Box onClick={() => setIsOpen(true)}>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  );
};

export default UserMenu;
