import { ReactNode, useMemo } from 'react'
import { UpdatePositionsReminder } from 'views/Farms/components/UpdatePositionsReminder'
import { useAccount } from 'wagmi'
import styled from 'styled-components'
import { BLOCKED_ADDRESSES } from './config/constants'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import { chains } from './utils/wagmi'

const WrapLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
`

const WrapContainerLayout = styled.div`
  background: rgba(248, 248, 248);
  width: 100%;
`

export function Updaters() {
  return (
    <>
      <UpdatePositionsReminder />
      <ListsUpdater />
      {chains.map((chain) => (
        <TransactionUpdater key={`trxUpdater#${chain.id}`} chainId={chain.id} />
      ))}
      <MulticallUpdater />
    </>
  )
}

export function Blocklist({ children }: { children: ReactNode }) {
  const { address: account } = useAccount()
  const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
  if (blocked) {
    return <div>Blocked address</div>
  }
  return (
    <WrapLayout>
      <WrapContainerLayout>{children}</WrapContainerLayout>
    </WrapLayout>
  )
}
