import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import Link from "next/link";
import MenuItems from "../../components/MenuItems/MenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import Footer from "../../components/Footer";
import { Button } from "../../components";
import BtnCopy from "../../BtnCopy";

const BlockHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  height: 60px;
  padding: 0px 10px;
  display: flex;
  background: #000;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 1199px) {
    padding: 0px 45px 0px 0px;
  }
`;
const BlockHeaderWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const BlockDirectLogo = styled(Link)`
  display: flex;
  align-items: center;
`;
const ImgLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const WrapWhitePaper = styled(Link)`
  button {
    margin: 0px 0px 0px 10px;
    height: 30px;
    padding: 0px 10px;
    border-radius: 5px;
    @media screen and (max-width: 1199px) {
      margin: 10px 0px 0px 0px;
    }
  }
`;

const BlockSmartContract = styled.div`
  position: absolute;
  top: 0px;
  z-index: 5;
  width: 100%;
  background: rgba(46, 67, 37);
  height: 100px;
  padding: 60px 0px 0px 0px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1199px) {
    height: 125px;
  }
`;

const BlockSmartContactWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;
const ItemSmartContract = styled.div`
  margin: 0px 35px;
  display: flex;
  align-items: center;
  button {
    margin: 0px 0px 0px 7.5px;
    @media screen and (max-width: 1199px) {
      transform: scale(0.85);
    }
  }
  @media screen and (max-width: 1199px) {
    font-size: 1.2rem;
    margin: 2.5px 10px;
    line-height: calc(1.2rem * 1.25);
  }
  @media screen and (max-width: 380px) {
    font-size: 1rem;
    margin: 2.5px 10px;
    line-height: calc(1rem * 1.25);
  }
`;

const ImgCopy = styled.img`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  buyCakeLink,
  children,
  chainId,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [_showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  // const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  // const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);

  const addressToken = "0x9b4a5bc99331b9adef07f37a39a4c4b0adc0c6fc";
  const [checkCopyToken, setCheckCopyToken] = useState(false);
  const addressNFT = "0x019a729A326886b08c92B0E50d95D04E298a1199";
  const [checkCopyNFT, setCheckCopyNFT] = useState(false);

  return (
    <MenuContext.Provider value={providerValue}>
      <AtomBox
        asChild
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
      >
        <>
          <BlockHeader>
            <BlockHeaderWrap className="blockContainer">
              <BlockDirectLogo href="/">
                <ImgLogo src="/logo.png" />
              </BlockDirectLogo>
              {rightSide}
            </BlockHeaderWrap>
          </BlockHeader>
          <Logo href={homeLink?.href ?? "/"} />
          <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px">
            <WrapWhitePaper href="https://xgroot.gitbook.io/whitepaper/" target="_blank">
              <Button>White Paper</Button>
            </WrapWhitePaper>
            <Footer
              chainId={chainId}
              items={footerLinks}
              isDark={isDark}
              toggleTheme={toggleTheme}
              langs={langs}
              setLang={setLang}
              currentLang={currentLang}
              cakePriceUsd={cakePriceUsd}
              buyCakeLabel={buyCakeLabel}
              buyCakeLink={buyCakeLink}
            />
          </MenuItems>
          <BlockSmartContract>
            <BlockSmartContactWrap className="blockContainer">
              <ItemSmartContract>
                Token - {addressToken}
                <BtnCopy
                  textCopy={`https://bscscan.com/address/${addressToken}`}
                  onCopy={() => {
                    setCheckCopyToken(true);
                    setTimeout(() => {
                      setCheckCopyToken(false);
                    }, 1250);
                  }}
                >
                  {checkCopyToken ? (
                    <ImgCopy src="/images/icCopyChecked.svg" alt="" />
                  ) : (
                    <ImgCopy src="/images/icCopy.svg" alt="" />
                  )}
                </BtnCopy>
              </ItemSmartContract>
              <ItemSmartContract>
                NFT - {addressNFT}
                <BtnCopy
                  textCopy={`https://bscscan.com/address/${addressNFT}`}
                  onCopy={() => {
                    setCheckCopyNFT(true);
                    setTimeout(() => {
                      setCheckCopyNFT(false);
                    }, 1250);
                  }}
                >
                  {checkCopyNFT ? (
                    <ImgCopy src="/images/icCopyChecked.svg" alt="" />
                  ) : (
                    <ImgCopy src="/images/icCopy.svg" alt="" />
                  )}
                </BtnCopy>
              </ItemSmartContract>
            </BlockSmartContactWrap>
          </BlockSmartContract>
          {children}
        </>
        {/* <Wrapper>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            {banner && isMounted && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
            <StyledNav>
              <Flex>
                <Logo href={homeLink?.href ?? "/"} />
                <AtomBox display={{ xs: "none", md: "block" }}>
                  <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" />
                </AtomBox>
              </Flex>
              <Flex alignItems="center" height="100%">
                <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
                  <CakePrice chainId={chainId} showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                </AtomBox>
                <Box mt="4px">
                  <LangSelector
                    currentLang={currentLang}
                    langs={langs}
                    setLang={setLang}
                    buttonScale="xs"
                    color="textSubtle"
                    hideLanguage
                  />
                </Box>
                {rightSide}
              </Flex>
            </StyledNav>
          </FixedContainer>
          {subLinks ? (
            <Flex justifyContent="space-around" overflow="hidden">
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight + 1}px`}
                activeItem={activeSubItem}
              />

              {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                <SubMenuItems
                  items={subLinksMobileOnly}
                  mt={`${totalTopMenuHeight + 1}px`}
                  activeItem={activeSubItem}
                  isMobileOnly
                />
              )}
            </Flex>
          ) : (
            <div />
          )}
          <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : "0"}>
            <Inner>{children}</Inner>
          </BodyWrapper>
        </Wrapper> */}
      </AtomBox>
    </MenuContext.Provider>
  );
};

export default Menu;
