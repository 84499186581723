import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | XGroot',
  defaultTitle: 'XGroot',
  description:
    'Join us in pioneering initiative leverages the power of blockchain technology and Web 3 to introduce a groundbreaking approach to environmental conservation.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@XGroot',
    site: '@XGroot',
  },
  openGraph: {
    title: 'XGroot',
    description:
      'Join us in pioneering initiative leverages the power of blockchain technology and Web 3 to introduce a groundbreaking approach to environmental conservation.',
    images: [{ url: 'https://market.xgroot.pro/bgBanner.jpg' }],
  },
}
