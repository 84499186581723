import type { PropsWithChildren } from "react";
import styled from "styled-components";

import { copyText } from "../../../../apps/web/src/utils/copyText";

interface CopyButtonProps {
  textCopy?: string | any;
  children?: any;
  onCopy?: (isCopy: boolean) => void;
}

const WrapCopy = styled.button`
  background: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:active {
    transform: scale(0.95);
  }
`;

const BtnCopy: React.FC<PropsWithChildren<CopyButtonProps>> = ({
  textCopy,
  children,
  onCopy,
}) => {
  const handleCopy = () => {
    if (onCopy) {
      onCopy(true);
      copyText(textCopy, () => {
        onCopy(false);
      });
    }
  };

  return <WrapCopy onClick={handleCopy}>{children}</WrapCopy>;
};
export default BtnCopy;
