import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <Svg viewBox="0 0 512 512">
      <path d="M478.585 105.747v-33.39h-33.39v-33.39H311.637v33.39h-33.39v33.39h-44.52v-33.39h-33.389v-33.39H66.779v33.39H33.39v33.389H0v200.338h33.39v33.39h33.389v33.39h33.39v33.389h66.779v33.39h66.779v33.39h44.52v-33.39h66.779v-33.39h66.779v-33.389h33.39v-33.39h33.39v-33.376H512V105.759z" />
    </Svg>
  );
};

export default Icon;
